<template>
  <div>
    <v-dialog
      v-if="selected_image"
      v-model="showImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Image</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img style="max-width: 100%;" :src="selected_image" alt="" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeImageDialog">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">{{ `${$t("salesforce.Edit")}` }}</span>
          </v-card-title>

          <v-card-text class="text--primary">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("route") }} : {{ selected.route_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("Date") }} : {{ selected.worksessionDate }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("User") }} : {{ selected.user_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ this.$t("pos") + " " + this.$t("brands.name") }} :
                    {{ selected.pos_name }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ $t("address") }} : {{ selected.address_label }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ `${this.$t("Theoretical")} - ${this.$t("visit_type")}` }}
                    : {{ selected.originalVisitType_label }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ `real - ${this.$t("visit_type")}` }}
                    : {{ selected.realVisitType_label }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ `${this.$t("comments")} not visit` }} :
                  </h6>
                  <v-textarea
                    v-model="selected.comments_not_visit"
                    class="gpv_comment_text readonly"
                    readonly
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ `${this.$t("comments")} change visit type` }} :
                  </h6>
                  <v-textarea
                    v-model="selected.comments_changed_visit_type"
                    class="gpv_comment_text readonly"
                    readonly
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{
                      `${this.$t("approved")} by ${this.$t(
                        "salesforce.Parent"
                      )}`
                    }}
                  </h6>
                  <v-autocomplete
                    v-model="selected.approvalStatus"
                    :items="approval_status"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("navbar.route_compliance") }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="filterForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.from"
                        v-bind:label="$t('brands.start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.from"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="endDate_menu"
                    v-model="select_endDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.to"
                        v-bind:label="$t('brands.end_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.to"
                      no-title
                      scrollable
                      @input="select_endDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idSPVs"
                    v-bind:label="`SPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_spvs"
                    :loading="isSearching['spvs']"
                    :items="filtered_spvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    @change="onChangeFilterMainCredentials('spvs')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idRoutes"
                    v-bind:label="$t('route')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_routes"
                    :loading="isSearching['routes']"
                    :items="filtered_routes"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idGPVs"
                    v-bind:label="`GPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_gpvs"
                    :loading="isSearching['gpvs']"
                    :items="filtered_gpvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.companyCodes"
                    :label="$t('Company')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_companies"
                    :loading="isSearching['companies']"
                    :items="filtered_companies"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="blue darken-1 mr-2" @click="onClickFilter">
              {{ $t("go") }}
            </v-btn>
            <v-btn color="warning" dark @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefRouteCompliance"
              id="datatable_reporting_routecompliance"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";

export default {
  name: "RouteCompliance",
  data: function() {
    return {
      visitTypes: [],
      approval_status: [],
      VISIT_TYPES: {},
      filter_main_credentials_fetch_url:
        "reporting/columna/getfiltermaincredentials",
      filterlist_fetch_url: "reporting/routecompliance/getfilterlist",
      filterable_col_list: [
        "route",
        "date",
        "user",
        "pos",
        "address_label",
        "originalVisitType",
        "realVisitType",
        "approvalStatus",
        "comments_not_visit",
        "comments_changed_visit_type",
        "isGpvPosCheckPresent"
      ],
      filterCriteria: {
        route: {},
        date: {},
        user: {},
        pos: {},
        address_label: "",
        originalVisitType: {},
        realVisitType: {},
        approvalStatus: {},
        comments_not_visit: "",
        comments_changed_visit_type: "",
        isGpvPosCheckPresent: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      totaldata: 0,
      data: [],

      /********** Filter form **********/
      filterMainCredential: {
        companyCodes: [],
        date: {
          from: null,
          to: null
        },
        idSPVs: [],
        idRoutes: [],
        idGPVs: []
      },
      select_startDate_menu: false,
      select_endDate_menu: false,
      isSearching: {},
      search_brands: null,
      filtered_brands: [],
      search_surveys: null,
      filtered_surveys: [],
      search_spvs: null,
      filtered_spvs: [],
      search_gpvs: null,
      filtered_gpvs: [],
      search_routes: null,
      filtered_routes: [],
      search_companies: null,
      filtered_companies: [],
      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      isSaving: false,
      selected_image: null,
      showImageDialog: false
    };
  },
  watch: {
    async search_companies(val) {
      this.searchValues("companies", val);
    },
    async search_brands(val) {
      this.searchValues("brands", val);
    },
    async search_surveys(val) {
      this.searchValues("surveys", val);
    },
    async search_routes(val) {
      this.searchValues("routes", val);
    },
    async search_spvs(val) {
      this.searchValues("spvs", val);
    },
    async search_gpvs(val) {
      this.searchValues("gpvs", val);
    }
  },
  computed: {
    showEmpty() {
      let ret = true;
      if (this.data.length > 0) {
        ret = false;
      }
      return ret;
    },
    col_route() {
      return {
        key: "route",
        title: this.$t("route"),
        field: "route",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["route"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`route`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["route"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.worksessionDate}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_userId() {
      return {
        key: "userId",
        title: "ID " + this.$t("User"),
        field: "userId",
        align: "left",
        width: 120,
        sortBy: ""
      };
    },
    col_user() {
      return {
        key: "user",
        title: this.$t("User"),
        field: "user",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["user"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`user`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["user"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: this.$t("address"),
        field: "address_label",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["address_label"]}
                  on-input={value =>
                    (this.filterCriteria["address_label"] = value)
                  }
                  placeholder="Search Address"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address_label")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_originalVisitType() {
      return {
        key: "originalVisitType",
        title: `${this.$t("Theoretical")} - ${this.$t("visit_type")}`,
        field: "originalVisitType",
        align: "left",
        sortBy: "",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.originalVisitType_label}</span>;
        }
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <filterableCheckboxesOthers
        //           v-model={this.filterCriteria["originalVisitType"]}
        //           dataList={this.visitTypes}
        //           filterCriteria={this.filterCriteria["originalVisitType"]}
        //           itemvalue="value"
        //           itemlabel="label"
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() =>
        //               this.searchCancel(closeFn, "originalVisitType")
        //             }
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_realVisitType() {
      return {
        key: "realVisitType",
        title: `real - ${this.$t("visit_type")}`,
        field: "realVisitType",
        align: "left",
        sortBy: "",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.realVisitType_label}</span>;
        }
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <filterableCheckboxesOthers
        //           v-model={this.filterCriteria["realVisitType"]}
        //           dataList={this.visitTypes}
        //           filterCriteria={this.filterCriteria["realVisitType"]}
        //           itemvalue="value"
        //           itemlabel="label"
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchCancel(closeFn, "realVisitType")}
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_originalBrandsLabel() {
      return {
        key: "originalBrandsLabel",
        title: `${this.$t("Theoretical")} - ${this.$t("brands.brands")}`,
        field: "originalBrandsLabel",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.originalBrandsLabel}</span>;
        }
      };
    },
    col_realBrandsLabel() {
      return {
        key: "realBrandsLabel",
        title: `Real - ${this.$t("brands.brands")}`,
        field: "realBrandsLabel",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.realBrandsLabel}</span>;
        }
      };
    },
    col_comments_not_visit() {
      return {
        key: "comments_not_visit",
        title: `${this.$t("comments")} not visit`,
        field: "comments_not_visit",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["comments_not_visit"]}
                  on-input={value =>
                    (this.filterCriteria["comments_not_visit"] = value)
                  }
                  placeholder="Search Comentarios not visit"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "comments_not_visit")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_comments_changed_visit_type() {
      return {
        key: "comments_changed_visit_type",
        title: `${this.$t("comments")} change visit type`,
        field: "comments_changed_visit_type",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["comments_changed_visit_type"]}
                  on-input={value =>
                    (this.filterCriteria["comments_changed_visit_type"] = value)
                  }
                  placeholder="Search Comentarios change visit type"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "comments_changed_visit_type")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_isGpvPosCheckPresent() {
      return {
        key: "isGpvPosCheckPresent",
        title: `¿GPV estaba reservado?`,
        field: "isGpvPosCheckPresent",
        align: "left",
        sortBy: "",
        width: 250,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {row.isGpvPosCheckPresent === null
                ? "NO CONCLUSIVA"
                : row.isGpvPosCheckPresent === true
                ? "SÍ"
                : "NO"}
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-checkbox
                  v-model={this.filterCriteria["isGpvPosCheckPresent"]["yes"]}
                  label="Sí"
                  value="yes"
                ></v-checkbox>
                <v-checkbox
                  v-model={this.filterCriteria["isGpvPosCheckPresent"]["no"]}
                  label="NO"
                  value="no"
                ></v-checkbox>
                <v-checkbox
                  v-model={
                    this.filterCriteria["isGpvPosCheckPresent"]["noconclusive"]
                  }
                  label="NO CONCLUSIVA"
                  value="noconclusive"
                ></v-checkbox>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "isGpvPosCheckPresent")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_photoId() {
      return {
        key: "photoId",
        title: `Foto`,
        field: "PhotoId",
        align: "left",
        width: 250,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          if (row?.static !== null) {
            let thumbnail_url = this.getImageUri(row.static);
            return (
              <img
                style="border: 1px solid #aaa; cursor: pointer; max-width: 100%; object-fit: cover; max-height: 120px"
                src={thumbnail_url}
                alt=""
                on-click={() => this.onItemShowImageDialog(row.static)}
              />
            );
          } else {
            return <span>&nbsp;</span>;
          }
        }
      };
    },
    col_approvalStatus() {
      return {
        key: "approvalStatus",
        title: `${this.$t("approved")} by ${this.$t("salesforce.Parent")}`,
        field: "approvalStatus",
        align: "left",
        sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.approvalStatus_label}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["approvalStatus"]}
                  dataList={this.approval_status}
                  filterCriteria={this.filterCriteria["approvalStatus"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "approvalStatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    columns() {
      return [
        this.col_route,
        this.col_date,
        this.col_userId,
        this.col_user,
        this.col_posId,
        this.col_pos,
        this.col_address_label,
        this.col_originalVisitType,
        this.col_realVisitType,
        this.col_originalBrandsLabel,
        this.col_realBrandsLabel,
        this.col_comments_not_visit,
        this.col_comments_changed_visit_type,
        this.col_isGpvPosCheckPresent,
        this.col_photoId,
        this.col_approvalStatus,
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "spvs") {
        this.filtered_spvs = resp.data;
      } else if (fieldname === "gpvs") {
        this.filtered_gpvs = resp.data;
      } else if (fieldname === "routes") {
        this.filtered_routes = resp.data;
      } else if (fieldname === "companies") {
        this.filtered_companies = resp.data;
      }
      this.isSearching[fieldname] = false;
    },
    onChangeFilterMainCredentials(fieldname) {
      this.searchValues(fieldname, "");
    },
    onItemShowImageDialog(image) {
      this.selected_image = this.getImageUri(image ? image : null);
      this.showImageDialog = true;
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected_image = null;
      });
    },
    getThumbnailUri(file) {
      return ApiService.getThumbnailUri(file);
    },
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    onChangeDates() {
      if (
        this.filterMainCredential.startDate > this.filterMainCredential.endDate
      ) {
        this.filterMainCredential.endDate = this.filterMainCredential.startDate;
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        cancelFilterKey === "address_label" ||
        cancelFilterKey === "comments_not_visit" ||
        cancelFilterKey === "comments_changed_visit_type"
      ) {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_reporting_routecompliance"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    async onClickFilter() {
      if (this.$refs.filterForm.validate()) {
        this.getDataFromApi();
      }
    },
    async getDataFromApi() {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#datatable_reporting_routecompliance .filterable-column"
      });
      let fetch_url = "reporting/routecompliance";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const resp = await ApiService.post(fetch_url, form_data);
        this.data = resp.data;
        this.totaldata = resp.total;
        this.visitTypes = resp.visitTypes;
        this.approval_status = resp.approval_status;
        this.VISIT_TYPES = resp.VISIT_TYPES;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      if (!this.$refs.filterForm.validate()) return;
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#datatable_reporting_routecompliance .filterable-column"
      });
      console.log(query_params_string);
      let fetch_url = "reporting/routecompliance/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const response = await ApiService.post(fetch_url, form_data, {
          responseType: "blob"
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      console.log("createSelectedModel - ", item);
      let model = {
        id: _.get(item, "id"),
        approvalStatus: _.get(item, "approvalStatus")
      };
      if (item) {
        model = { ...item, ...model };
      }
      return model;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        const { id, approvalStatus } = item;
        let body = { approvalStatus };
        if (id) {
          try {
            await ApiService.put(`reporting/routecompliance/${id}`, body);
            logInfo("Éxito !");
            this.showEdit = false;
            this.getDataFromApi();
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    async init() {
      this.searchValues("spvs", "");
      this.searchValues("gpvs", "");
      this.searchValues("routes", "");
      this.searchValues("companies", "");
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
